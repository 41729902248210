import React, { useEffect } from "react";
import {navigate} from 'gatsby';
import ReactDynamicImport from 'react-dynamic-import';
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/Schedule/ScheduleRegister')
    )
  } else {
    return (
      import('@components/Schedule/ScheduleRegisterMobile')
    )
  }
}

const Schedule = ReactDynamicImport({ loader })

const SchedulePage = () => {
  useEffect(() => {
    navigate('/404');
  }, [])

  return <Schedule />
}

export default SchedulePage;
